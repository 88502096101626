#bigCanvas {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}

#bigcanvasContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  padding: 0px;
  text-align: center;
  z-index: 9999999999999999999999999999999999999999999999999;
  overflow: hidden;
  color: white;
}

#showButton {
  position: absolute;
  bottom: 12%;
  right: 5%;
  border-radius: 15px;
  border: 2px solid #73ad21;
  padding: 5px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 20%;
  font-size: 24px;
  z-index: 1501;
  font-family: Open Sans;
}
#imageButton {
  position: absolute;
  bottom: 12%;
  right: 15%;
  border-radius: 15px;
  border: 2px solid #73ad21;
  padding: 5px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 20%;
  font-size: 24px;
  z-index: 1501;
  font-family: Open Sans;
}
#relButton {
  position: absolute;
  bottom: 12%;
  right: 32%;
  border-radius: 15px;
  border: 2px solid #73ad21;
  padding: 5px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 20%;
  font-size: 24px;
  z-index: 1501;
  font-family: Open Sans;
}
#saveDetail {
  position: absolute;
  top: 1%;
  left: 1%;
  border-radius: 15px;
  border: 2px solid #777777;
  padding: 5px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 50%;
  height: 92%;
  font-size: 24px;
  z-index: 1501;
  font-family: Open Sans;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
#shortText {
  position: absolute;
  top: 1%;
  right: 1%;
  border-radius: 15px;
  border: 2px solid #777777;

  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 50%;
  height: 75%;
  padding: 2%;
  font-size: 21px;
  font-weight: bold;
  z-index: 1501;
  font-family: Open Sans;
  overflow: auto;
  text-align: center;
  vertical-align: middle;
}

#boxHider {
  position: absolute;
  top: -1%;
  left: -1%;
  border-radius: 10px;
  border: 2px solid #777777;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 0, 0, 1);
  color: #fff;
  font-size: 12px;
  z-index: 99501;
  font-family: Open Sans;
}
#topButton {
  border-radius: 15px;
  border: 2px solid #0cf;
  -moz-box-shadow: 2px 2px 6px #222;
  -webkit-box-shadow: 2px 2px 6px #222;
  box-shadow: 2px 2px 6px #222;
  font-size: 30px;
  font-family: Open Sans;
  background-color: rgba(0, 0, 0, 1);
  z-index: 1725;
  color: #fff;
  position: absolute;
  top: 1%;
  left: 7%;
}

#mainButton {
  align: center;
  vertical-align: middle;
  border-radius: 15px;
  border: 2px solid #73ad21;
  font-size: 30px;
  font-weight: bolder;
  font-family: Open Sans;
  z-index: 995729;
  color: white;
  position: absolute;
  top: 1%;
  left: 10%;
  height: 50px;
  background-color: rgba(0, 0, 0, 1);
}

#shim {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.65);
  font-family: Open Sans;

  border: 2px solid #0c0;
  -moz-box-shadow: 2px 2px 6px #222;
  -webkit-box-shadow: 2px 2px 6px #222;
  box-shadow: 2px 2px 6px #222;
  font-size: 30px;
  padding-left: 20px;
  z-index: 1720;
  color: #ffaa00;
  width: 20%;
  height: 50px;
  position: absolute;
  top: 1%;
  right: 1%;
}
#share {
  vertical-align: middle;
  position: absolute;
  top: 10%;
  left: 1%;
  z-index: 19501;
  background-color: rgba(0, 0, 0, 0);
  height: 13%;
}

#searches {
  font-family: Open Sans;
  font-size: 36px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #ff0;
  -moz-box-shadow: 2px 2px 6px #222;
  -webkit-box-shadow: 2px 2px 6px #222;
  box-shadow: 2px 2px 6px #222;
  padding-left: 20px;
  overflow: auto;
  z-index: 2720;
  color: white;
  position: absolute;
  top: 20%;
  right: 20%;
  left: 20%;
  bottom: 20%;
}

#searchButton {
  border-radius: 15px;
  background-color: #111;
  opacity: 30%;
  font-family: Open Sans;

  border: 2px solid #73ad21;
  -moz-box-shadow: 2px 2px 6px #222;
  -webkit-box-shadow: 2px 2px 6px #222;
  box-shadow: 2px 2px 6px #222;
  width: 75px;
  height: 50px;
  z-index: 2725;
  color: white;
  font-size: 36px;
  padding-right: 5px;
  position: relative;
  top: 1%;
  right: 1%;
  display: none;
}

#searchButton2 {
  border-radius: 15px;
  background-color: #111;
  opacity: 30%;
  font-family: Open Sans;

  border: 2px solid #0c0;
  -moz-box-shadow: 2px 2px 6px #222;
  -webkit-box-shadow: 2px 2px 6px #222;
  box-shadow: 2px 2px 6px #222;
  width: 75px;
  height: 50px;
  z-index: 2725;
  color: white;
  font-size: 36px;
  padding-right: 5px;
  position: relative;
  top: 1%;
  right: 1%;
  display: none;
}
#hideButton {
  position: absolute;
  top: 4%;
  left: 6%;
  border-radius: 10px;
  border: 2px solid #777777;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 0, 0, 1);
  color: #fff;
  font-size: 12px;
  z-index: 3703;
  font-family: Open Sans;
  display: none;
}

button {
  padding: 5px;

  height: 50px;

  z-index: 1705;
  font-family: Open Sans;
}

input[type="text"] {
  width: 200px;
  height: 20px;
  padding-right: 0px;
  font-family: Open Sans;
}
::-webkit-scrollbar {
  width: 2%; /* for vertical scrollbars */
  border-radius: 15px 15px 15px 0px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 215, 0, 0.2);
  border-radius: 15px 15px 15px 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #73ad21;
  border-radius: 15px 15px 15px 0px;
}
input[type="submit"] {
  font-family: Open Sans;
  height: 25px;
}

#infolinks {
  font-size: 18px;
  align: left;
  border: 2px solid #00cc00;
  position: absolute;
  bottom: 0;
  color: white;
  right: 0;
  width: 120px;

  background-color: rgba(0, 0, 0, 0.6);
  z-index: 159901;
}

#colophon {
  font-size: 12px;
  align: justify;
  border: 2px solid #ffbb00;
  position: absolute;
  bottom: 0;
  color: white;
  right: 0;

  background-color: rgba(0, 0, 0, 0.6);
  z-index: 159901;
}

#adBox {
  align: left;
  border-radius: 5px;
  border: 3px solid #ffbb00;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 120px;

  background-color: rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 9px 9px 24px #222;
  -webkit-box-shadow: 9px 9px 24px #222;
  box-shadow: 10px 10px 24px #222;
  z-index: 1501;
  font-family: Open Sans;
}
#inHouseAdvert {
}
#shimBtm {
  align: center;
  vertical-align: middle;
  border-radius: 15px;
  border: 2px solid #73ad21;
  position: absolute;
  bottom: 15%;
  right: 25%;
  left: 25%;

  background-color: rgba(0, 0, 0, 0.6);
  width: 60%;
  font-size: 24px;
  height: 50%;
  z-index: 1501;
  font-family: Open Sans;
}
#chariCard {
  border-radius: 15px;
  border: 2px solid #73ad21;
  -moz-box-shadow: 9px 9px 24px #222;
  -webkit-box-shadow: 9px 9px 24px #222;
  box-shadow: 10px 10px 24px #222;
  height: 80%;
  width: 80%;
  z-index: 3702;
  color: white;
  font-family: Open Sans;
  align: left;
  font-size: 24px;
  padding-right: 40px;
}
.card-container {
  text-align: justify;
  margin-bottom: 2.5em;
  position: fixed;
  padding: 40px;
  font-family: Open Sans;

  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 1702;
  overflow: auto;

  width: 100%;
  height: 100%;
}
a.classyLink:hover {
  cursor: pointer;
}

div.captioned {
  float: right;
  width: 31%;
  margin: 5px;
  font-family: Open Sans;

  padding: 20px;
  text-align: right;
  font-size: 14px;
  font-style: italic;
  vertical-align: text-bottom;
}
div.captioned p {
  text-align: center;
  font-style: italic;
  font-family: Open Sans;

  font-size: smaller;
  text-indent: 0;
}
div.captioned img {
  width: 95%;
  align: center;
  font-family: Open Sans;
}

#cardCanvas {
  position: relative;
  top: 10;
  font-family: Open Sans;

  left: 10;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999;
}
.title {
  text-align: center;
  font-family: Open Sans;
  font-size: 2.2em;
  margin: 1em;
}
.info {
  text-align: center;
  font-family: Open Sans;
  font-size: 1.2em;
  margin: 0.25em;
}

a {
  color: #0c0;
  text-decoration: underline;
}

img {
  border-radius: 15px;
  float: left;
  border: 2px solid #73ad21;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.25);
}

body {
  background-color: rgba(0, 0, 15, 1);
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: white;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

a,
a:visited,
a:active,
a:hover {
  color: #fff;
  text-decoration: underline;
}

p {
  display: inline;
}

img {
  border-radius: 15px;
  float: left;
  border: 2px solid #73ad21;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.25);
}
button {
  padding: 5px;
  height: 50px;
  z-index: 1705;
}

input[type="text"] {
  width: 200px;
  height: 20px;
  padding-right: 0;
}
input[type="submit"] {
  height: 25px;
}

.curtain {
  background-color: rgba(0, 0, 0, 0.95);
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4999;
}
.curtain2 {
  background-color: rgba(0, 0, 15, 1);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999999999999999999999999999999;
}
.card {
  color: rgba(53, 96, 148, 1);
  background-color: rgba(53, 96, 148, 0.4);
  border-radius: 15px 15px 15px 15px;
  border: solid 1px rgba(53, 96, 148, 0.85);
  transition-duration: 0.2s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  display: flex;
  top: 10%;
  left: 5%;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.card .card-content {
  margin: auto;
  text-align: center;
  transform-style: preserve-3d;
}

.card-content h1 {
  color: #fff;
  transform: translateZ(80px);
  font-size: 20pt;
}

.card-content h2 {
  color: rgba(255, 255, 255, 1);
  transform: translateZ(60px);
  font-size: 14pt;
}

.card-content h3 {
  color: rgba(255, 255, 255, 1);
  transform: translateZ(80px);
  font-size: 16pt;
  padding: 0 20px;
}

.card-content p {
  transform: translateZ(50px);
  font-size: 12pt;
  font-style: italic;
}

.card-content p.related {
  transform: translateZ(80px);
  font-style: italic;
}

.card-content a {
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(53, 96, 148, 0.8);
  padding: 5px 15px;
  margin: 10px 10px 10px 10px;
  text-decoration: none;
  border-radius: 4px;
  transform: translateZ(180px);
}

.card-content a:hover {
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(53, 96, 148, 0.8);
  transform: translateZ(220px);
  text-decoration: underline;
}

.card-content a:active {
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(53, 96, 148, 0.8);
  border: solid 1px #203e5f;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 25px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999999999999999999999999999999999999999999;
  width: 100%;
  padding: 5px;
  text-align: center;
}

.footer-content {
  justify-content: space-between;
  width: 90%;
  text-align: center;
  text-decoration: none;
  display: flex;
}

.footer-content a {
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  background: rgba(53, 96, 148, 0);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.footer-content a:hover {
  background: #356094;
  text-decoration: underline;
}

.footer-content a:active {
  -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  background: #2e5481;
  text-decoration: underline;
}

.footer-content button {
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  background: rgba(53, 96, 148, 0);
  border-radius: 4px;
}

.footer-content button:hover {
  background: #356094;
  text-decoration: underline;
}

.footer-content button:active {
  -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
  background: #2e5481;
  text-decoration: underline;
}

.loading {
  position: fixed;
  margin-left: 33%;
  margin-right: 33%;
  margin-top: 15%;
}

.sk-circle {
  margin: 25% auto;
  width: 140px;
  height: 140px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: rgba(53, 96, 148, 0.8);
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.menu {
  -webkit-filter: url("#shadowed-goo");
  filter: url("#shadowed-goo");
}

.menu-item,
.menu-open-button {
  background-color: rgba(53, 96, 148, 0.8);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  position: absolute;
  top: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.hamburger {
  width: 25px;
  height: 3px;
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  transition: transform 200ms;
}

.hamburger-1 {
  transform: translate3d(0, -8px, 0);
}

.hamburger-2 {
  transform: translate3d(0, 0, 0);
}

.hamburger-3 {
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}
.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
  position: absolute;
  top: 5%;
  right: 2%;
  margin-right: -190px;
  padding-top: 20px;
  padding-right: 190px;
  width: 380px;
  height: 250px;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
  visibility: hidden;
}

.menu-item:hover {
  background: white;
  color: #ff4081;
}
.menu-item:nth-child(3) {
  transition-duration: 70ms;
}
.menu-item:nth-child(4) {
  transition-duration: 130ms;
}
.menu-item:nth-child(5) {
  transition-duration: 190ms;
}
.menu-item:nth-child(6) {
  transition-duration: 250ms;
}
.menu-item:nth-child(7) {
  transition-duration: 310ms;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}
.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 160ms;
  transform: translate3d(114.42548px, 11.48084px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 240ms;
  transform: translate3d(77.18543px, 85.2491px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 320ms;
  transform: translate3d(0.09158px, 114.99996px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 400ms;
  transform: translate3d(-77.04956px, 85.37192px, 0);
}
.menu-open:checked ~ .menu-item:nth-child(7) {
  transition-duration: 480ms;
  transform: translate3d(-114.40705px, 11.66307px, 0);
}

.jack {
  width: 50%;
  background-color: rgba(200, 200, 200, 0.6);
  padding: 15px;
  color: #000;
  overflow: hidden;
  text-align: justify-all;
  z-index: 9999999999999999999999999999999999999999999999999;
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  box-shadow: 5px 5px 0 0 #aaa;
  border-radius: 15px;
}
.jack h1 {
  margin: 0;
  padding: 5px;
  text-align: center;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  animation: fadein 1.2s;
  border-radius: 15px;
}
.jack img {
  width: 200px;
  height: 200px;
  margin: 10px;
  position: relative;
  z-index: 1;
  animation: grow 1.6s;
}
.jack a {
  color: #0000ff;
}
.jack .bio {
  position: relative;
  margin: 20px;
  animation: slidein 1.2s;
}

@keyframes fadein {
  from {
    color: transparent;
  }
  to {
    color: inherit;
  }
}
@keyframes grow {
  from {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slidein {
  from {
    opacity: 0;
  }
  49.9% {
    opacity: 0;
  }
  50% {
    left: -240px;
  }
  to {
    left: 0;
  }
}
